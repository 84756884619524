<template>
  <div class="detail">
    <el-page-header @back="$router.back()" content="会员详情"></el-page-header>

    <!-- 预览部分 -->
    <div class="detail-preview"
         :style="{background: vip.bgColor}">
      <div class="bg"></div>
      <div>
        <div class="title">注册会员</div>
      </div>
      <div class="content">
        <div class="content-number">7</div>
        成长值
      </div>
      <el-divider></el-divider>
      <div class="next">再获得 0 成长值成为 LV1</div>
      <div class="tip">仅为预览，实际以用户查看时为准</div>
    </div>

    <!-- 预览部分结束 -->

    <div class="detail-form">
      <el-form label-width="120px" size="small">
        <p class="divider">
          <span class="divider-title">基本设置</span>
        </p>
        <el-form-item>
          <span class="yk-label__require" slot="label">等级名称：</span>
          <el-input v-model="vip.name"
                    style="width: 192px"
                    maxlength="12"
                    show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item>
          <span class="yk-label__require" slot="label">等级背景：</span>
          <div>
            <div class="detail-color-container">
              <el-radio v-model="vip.bgStyle" :label="1">背景色</el-radio>
              <el-color-picker v-model="vip.bgColor" style="width: 80px"></el-color-picker>
            </div>
            <div>
              <el-radio v-model="vip.bgStyle" :label="2">背景图</el-radio>
              <material-manager v-model="vip.bgImg" :limit="1"
                                :preview="false"
                                @change="(url) => vip.bgImg=url"
                                :width="'100px'" :height="'100px'"></material-manager>
              <p class="yk-tip">尺寸：1000*600像素，小于1M，支持jpg、png、jpeg格式</p>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <span class="yk-label__require" slot="label">触发成长值：</span>
          <el-input v-model.number="vip.number"
                    style="width: 192px">
          </el-input>
          <p class="yk-tip">修改等级所需成长值后，部分会员会因无法达到该成长值要求而发生会员等级的变化。<br/>降级【不影响】已发送礼包。</p>
        </el-form-item>

        <p class="divider">
          <span class="divider-title">权益礼包</span>
          <span class="yk-tip">用户会员升级自动发放权益礼包</span>
        </p>
        <el-form-item label="权益：">
          <div class="detail-form-percent" v-for="(right, idx) in rights" :key="idx">
            <el-checkbox v-model="right.check">{{right.name}}</el-checkbox>
            <template v-if="right.input  && right.check">
              <el-input class="input-number" v-model.number="right.value">
                <span slot="append">{{right.unit}}</span>
              </el-input>
            </template>
          </div>
        </el-form-item>

        <el-form-item label="升级礼包：">
          <div class="detail-form-percent" v-for="(gift, idx) in gifts" :key="idx">
            <el-checkbox v-model="gift.check">{{gift.name}}</el-checkbox>
            <template v-if="gift.show">
              <template v-if="gift.label === 'number'">
                <el-input class="input-number" v-model.number="gift.value"></el-input>
                <span>{{gift.unit}}</span>
              </template>
              <template v-if="gift.label === 'ticket'">
                <p class="yk-tip">发放优惠券能有效刺激转化</p>
                <el-button v-if="gift.check" type="text">选取优惠券</el-button>
              </template>

              <template v-if="gift.label === 'drink'">
                <p class="yk-tip">促进资深客户沉淀</p>
                <el-button v-if="gift.check" type="text">选取饮品</el-button>
              </template>
            </template>
          </div>
        </el-form-item>

        <p class="divider">
          <span class="divider-title">可用促销</span>
          <span class="yk-tip">用户可使用相关促销功能</span>
        </p>

        <el-form-item>
          <el-button @click="$router.back()">取消</el-button>
          <el-button type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

  export default {
    name: '',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        rights: [
          {
            name: '商品包邮',
            check: false,
          },
          {
            name: '消费折扣',
            check: false,
            input: true,
            value: 0,
            unit: '折'
          },
          {
            name: '积分回馈倍率',
            check: false,
            value: 0,
            input: true,
            unit: '倍'
          }
        ],
        gifts: [
          {
            label: 'number',
            name: '送',
            show: true,
            value: 0,
            unit: '积分'
          },
          {
            label: 'ticket',
            name: '送优惠券',
            show: true,
          },
          {
            label: 'exchange',
            name: '送兑换券',
            show: true,
          },
          {
            label: 'transfer',
            name: '物流减免',
            show: true,
          }
        ],
        vip: {
          name: '',
          bgStyle: 1,
          bgColor: this.$themeColor,
          bgImg: '',
        }
      }
    },
    methods: {},
  }
</script>

<style lang="scss">
  .detail {
    @include container();
    position: relative;


    &-preview {
      width: 343px;
      height: 180px;
      border-radius: 12px;
      box-sizing: border-box;
      padding: 17px 20px;
      color: #fff;
      overflow: visible;
      position: absolute;
      top: 60px;
      right: 100px;

      .el-divider--horizontal {
        height: 2px;
        margin-bottom: 0;
        background: #fff;
      }

      .title {
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
        -ms-flex-align: center;
        align-items: center;
        mix-blend-mode: screen;
      }

      .content {
        display: flex;
        padding-top: 30px;
        -ms-flex-align: baseline;
        align-items: baseline;
        font-weight: 500;

        &-number {
          font-size: 26px;
          margin-right: 8px;
        }
      }

      .bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url("../../../../assets/img/member.png") center center / cover no-repeat;
      }

      .next {
        font-size: 13px;
        padding-top: 20px;
      }

      .tip {
        color: #969799;
        bottom: -24px;
        left: 0;
        right: 0;
        font-size: 12px;
        position: absolute;
        text-align: center;
      }
    }

    &-form {
      max-width: 640px;
      margin-top: 32px;

      .el-color-picker {
        width: 80px;

        .el-color-picker__trigger {
          width: 100%;
        }
      }

      &-percent {
        margin-bottom: 32px;
      }

      .input-number {
        width: 120px;
        margin-left: $small-space;
        margin-right: $small-space;
      }

      .yk-tip {
        line-height: 1.7;
      }

      .divider {
        padding-bottom: $middle-space;
        margin-bottom: $container-padding;
        border-bottom: 1px solid $content-border-color;
        &-title {
          @include font-medium();
          color: $color-black;
          margin-right: $middle-space;
        }
      }


    }

    &-color-container {
      display: flex;
      align-items: center;

      *[class*="el-icon-"] {
        display: none;
      }
    }
  }
</style>
